
.Bar{
	/*background-color: rgb(54, 84, 122);*/
	background-color: rgb(17 24 39);
	width : 100%;
	position: fixed;
	z-index: 1;
}


.NavItem{
	background-color: rgb(17 24 39);
	color: #fff;
	text-decoration: none;
	font-size: 120%;
	text-align: center;
	
	width : 20%;
	margin-left:4%;
	margin-top: 1%;
	margin-bottom : 1%;

	
	display:inline-block;
	
	
	
	border-radius:5px;
	
	

	
}

.NavItem:hover {
	font-weight: bold;
	
	/*
	color:black;
	background-color:whitesmoke;
	*/

	transition-duration: 200ms;
	transition-property: color, border-radius, font-weight, background-color;
	
	
}