

.section{
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 20px;
	padding-bottom: 20px;
	/*color: rgb(156 163 175);*/
	/*background-color: rgb(17 24 39);*/
	background-color:whitesmoke;
	color : black;

    text-align: center;
}

.ContactContainer{
	display:flex;

}

.ContactGauche{
	width: 50%;
}

.ContactDroite{
	width: 50%;
}

.iframe{
	border:2px solid black;
	border-radius:20px;
}