.projectsSection{
	color: rgb(156 163 175);
	/*background-color: rgb(17 24 39);*/
	background-color:whitesmoke;
	
}
	
.containerDiv{
	width:100%;
	margin: auto;
	padding-left: auto;
	padding-right: auto;
	padding-bottom: 1%;	
	text-align: center;
}

.AllCards{

}

.ProjectCard{
	/*background-color: rgb(41 51 65);*/
	/*background-color:rgb(58, 11, 99);*/
	/*background-color:rgb(31, 3, 56);*/
	background-color: rgb(17 24 39);
	border-radius:5px;
	margin-top:1%;
	margin-left: auto;
    margin-right: auto;
	
	display:flex; flex-direction:column;
	width : 90%;

}

.ProjectCard:hover{	
	background-color:rgb(13, 14, 86);
	color:black;
	border-radius:15px;
	transition-duration: 800ms;
	transition-property: color, border-radius, background-color;

}

.text{
	width:50%;
	margin-top:1%;
	float: left;
}

.image{
	width:40%;
	float: right;
	margin-top:1%;
	margin-bottom:1%;
	margin-right : 2%;
	border:2px solid black;
	border-radius:20px;
	
}

.h1{
	text-decoration: none;
	color:white;
}
.h3{
	text-decoration: none;
	color:white;
}
.p{
	text-decoration: none;
	color:white;
}

