
.section{	
	/*background-color: rgb(17 24 39);*/
	background-color:whitesmoke;
	width : 100%;
	margin-left : auto;
	margin-right :auto;
	padding-top : 6%;
	padding-bottom : 1%;
	display:flex; 
}

.link{
	width:20%;
	margin-left : 5%;
	margin-right : 5%;
	border-radius:5%;
	float: left;
	
}

.image{
	width:100%;
	filter: grayscale(100%);
	border:2px solid black;
	border-radius:20px;
}

.text{		
	font-size: 150%;
	
	text-align: center;
	/*background-color: rgb(17 24 39);*/
	/*color: white;*/
	color : rgb(17 24 39);
	float: right;
	width : 65%;

}


